
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const CVParPage = () => (
  <Layout>
    <div className='top'></div>
    <SEO title="CV Par" />
    <div>This is the CP Par page</div>
  </Layout>

)
export default CVParPage

// import React from "react"
// import SEO from "../components/seo"
// import Header from "../components/header"
// import Footer from "../components/footer"
// import Bandeau from "../components/bandeau"
// import HoverPict from "../components/hoverpict"

// import { Button } from "react-bootstrap"
// import Container from "react-bootstrap/Container"
// import Row from "react-bootstrap/Row"
// import Col from "react-bootstrap/Col"

// import { ParallaxLayer } from "react-spring/renderprops-addons"
// // import { Parallax } from "react-spring/renderprops-addons"
// // import CVParallax from "../containers/cvparall_tmp"

// import { useEffect } from "react"
// import Aos from "aos"
// import "aos/dist/aos.css"

// // let parallax = ""
// const CVPage = () => {
//   useEffect(() => {
//     Aos.init({ duration: 5000 })
//   }, [])
//   return (
//     <div>
//       <SEO title="CV | Thibaud LAMOTHE" />
//       <Header siteTitle="etomal" />
//       <br />
//       <br />

//       <Container fluid>
//         <Row>
//           <Col sm className="p-0">
//             <Bandeau
//               title="Curriculum Vitae"
//               subtitle="Discover my career"
//               id="cvhead"
//             >
//               <Row className="d-flex justify-content-center mt-5 mb-5">
//                 <p className="text-center" id="cvText">
//                   I'm Thibaud Lamothe, a Data Scientist working in a retail
//                   firm's Data Factory. On a daily basis I work with Python and
//                   Azure. The key learning from my previous experience in a
//                   consulting firm is that working with Data is fun, but added
//                   value makes it all. That's why I also work for OpenClassroom,
//                   a french online course plateform where I mentor students on a
//                   weekly basis.
//                 </p>
//               </Row>
//               <Row></Row>
//               <Row className="d-flex justify-content-center mb-5">
//                 <HoverPict
//                   url="https://www.linkedin.com/in/thibaud-lamothe/"
//                   pictBack="sn_linkedin_dark.png"
//                   pictFront="sn_linkedin_dark_light.png"
//                 ></HoverPict>

//                 <HoverPict
//                   url="https://github.com/ThibaudLamothe"
//                   pictBack="sn_github_dark.png"
//                   pictFront="sn_github_dark_light.png"
//                 ></HoverPict>

//                 <HoverPict
//                   url="https://medium.com/@thibaud.lamothe2"
//                   pictBack="sn_medium_crop_dark.png"
//                   pictFront="sn_medium_crop_dark_light.png"
//                 ></HoverPict>

//                 <HoverPict
//                   url="mailto:hello@etomal.com"
//                   pictBack="sn_email_dark.png"
//                   pictFront="sn_email_dark_light.png"
//                 ></HoverPict>
//               </Row>

//               <Row className="d-flex justify-content-center mb-3">
//                 <Button href="/CV_Thibaud_Lamothe.pdf" className="button">
//                   Get PDF Version
//                 </Button>
//               </Row>

//               <Row className="d-flex justify-content-center mb-3">
//                 <Button href="/CV_Thibaud_Lamothe.pdf" className="button">
//                   Contact me
//                 </Button>
//               </Row>

//               <Row></Row>
//             </Bandeau>
//           </Col>

//           <Col sm className="p-0 d-flex align-items-center" id="pictThib"></Col>
//         </Row>
//       </Container>
//       {/* </ParallaxLayer> */}

//       <ParallaxLayer offset={1.1}>
//         <p className="expTeach">
//           <br />
//         </p>
//         <p data-aos="fade-up" className="expTeach colored">
//           OC
//         </p>
//         <p className="expTeach">
//           <br />
//         </p>
//         <p className="expTeach">
//           <br />
//         </p>
//         <p className="expTeach">
//           <br />
//         </p>
//         <p className="expTeach">
//           <br />
//         </p>
//         <p className="expTeach"></p>
//         <p className="expTeach colored">X</p>
//         <p className="expTeach">
//           <br />
//         </p>
//         <p className="expTeach">
//           <br />
//         </p>
//         <p className="expTeach">
//           <br />
//         </p>
//         <p className="expTeach">
//           <br />
//         </p>
//         <p className="expTeach colored">ETS</p>
//         <p className="expTeach">
//           <br />
//         </p>
//         <p className="expTeach">
//           <br />
//         </p>
//         <p className="expTeach">
//           <br />
//         </p>
//         <p className="expTeach">
//           <br />
//         </p>
//         <p className="expTeach">
//           <br />
//         </p>
//         <p className="expTeach">
//           <br />
//         </p>
//         <p className="expTeach">
//           <br />
//         </p>
//         <p className="expTeach">
//           <br />
//         </p>
//         <p className="expTeach">
//           <br />
//         </p>
//         <p className="expTeach">
//           <br />
//         </p>
//         <p className="expTeach">
//           <br />
//         </p>
//         <p className="expTeach">
//           <br />
//         </p>
//         <p className="expTeach">
//           <br />
//         </p>
//         <p className="expTeach colored">School</p>
//         <p className="expTeach">
//           <br />
//         </p>
//       </ParallaxLayer>

//       {/* <Fade left appear={false} delay={300}> */}
//       <ParallaxLayer offset={1.1}>
//         <p className="expPro">
//           <br />
//         </p>
//         <p className="expPro colored">Mousquetaires</p>
//         <p className="expPro">
//           <br />
//         </p>
//         <p className="expPro">
//           <br />
//         </p>
//         <p className="expPro">
//           <br />
//         </p>
//         <p className="expPro">
//           <br />
//         </p>
//         <p className="expPro">
//           <br />
//         </p>
//         <p className="expPro colored">Capgemini</p>
//         <p className="expPro">
//           <br />
//         </p>
//         <p className="expPro colored">Bosch</p>
//         <p className="expPro">
//           <br />
//         </p>
//         <p className="expPro">
//           <br />
//         </p>
//         <p className="expPro colored">Nero</p>
//         <p className="expPro">
//           <br />
//         </p>
//         <p className="expPro colored">GeoSoin</p>
//         <p className="expPro">
//           <br />
//         </p>
//         <p className="expPro">
//           <br />
//         </p>
//         <p className="expPro colored">GeoSoin</p>
//         <p className="expPro">
//           <br />
//         </p>
//         <p className="expPro">
//           <br />
//         </p>
//         <p className="expPro">
//           <br />
//         </p>
//         <p className="expPro">
//           <br />
//         </p>
//         <p className="expPro">
//           <br />
//         </p>
//         <p className="expPro">
//           <br />
//         </p>
//         <p className="expPro">
//           <br />
//         </p>
//         <p className="expPro">
//           <br />
//         </p>
//         <p className="expPro">
//           <br />
//         </p>
//         <p className="expPro">
//           <br />
//         </p>
//       </ParallaxLayer>

//       <ParallaxLayer offset={1.1}>
//         <p className="yearCV colored">2021</p>
//         <p className="yearCV">
//           <br />
//         </p>
//         <p className="yearCV">
//           <br />
//         </p>
//         <p className="yearCV colored">2020</p>
//         <p className="yearCV">
//           <br />
//         </p>
//         <p className="yearCV">
//           <br />
//         </p>
//         <p className="yearCV colored">2019</p>
//         <p className="yearCV">
//           <br />
//         </p>
//         <p className="yearCV">
//           <br />
//         </p>
//         <p className="yearCV colored">2018</p>
//         <p className="yearCV">
//           <br />
//         </p>
//         <p className="yearCV">
//           <br />
//         </p>
//         <p className="yearCV colored">2017</p>
//         <p className="yearCV">
//           <br />
//         </p>
//         <p className="yearCV">
//           <br />
//         </p>
//         <p className="yearCV colored">2016</p>
//         <p className="yearCV">
//           <br />
//         </p>
//         <p className="yearCV">
//           <br />
//         </p>
//         <p className="yearCV colored">2015</p>
//         <p className="yearCV">
//           <br />
//         </p>
//         <p className="yearCV">
//           <br />
//         </p>
//         <p className="yearCV colored">2014</p>
//         <p className="yearCV">
//           <br />
//         </p>
//         <p className="yearCV">
//           <br />
//         </p>
//         <p className="yearCV colored">2013</p>
//         <p className="yearCV">
//           <br />
//         </p>
//         <p className="yearCV">
//           <br />
//         </p>
//         <p className="yearCV colored">2012</p>
//       </ParallaxLayer>

//       <ParallaxLayer offset={1.1}>
//         <p className="schoolCV">
//           <br />
//         </p>
//         <p className="schoolCV">
//           <br />
//         </p>
//         <p className="schoolCV colored">DeepL</p>
//         <p className="schoolCV">
//           <br />
//         </p>
//         <p className="schoolCV">
//           <br />
//         </p>
//         <p className="schoolCV">
//           <br />
//         </p>
//         <p className="schoolCV">
//           <br />
//         </p>
//         <p className="schoolCV colored">DataCamp</p>
//         <p className="schoolCV">
//           <br />
//         </p>
//         <p className="schoolCV colored">Coursera</p>
//         <p className="schoolCV">
//           <br />
//         </p>
//         <p className="schoolCV">
//           <br />
//         </p>
//         <p className="schoolCV">
//           <br />
//         </p>
//         <p className="schoolCV colored">ETS</p>
//         <p className="schoolCV">
//           <br />
//         </p>
//         <p className="schoolCV">
//           <br />
//         </p>
//         <p className="schoolCV">
//           <br />
//         </p>
//         <p className="schoolCV">
//           <br />
//         </p>
//         <p className="schoolCV">
//           <br />
//         </p>
//         <p className="schoolCV colored">ENSAM</p>
//         <p className="schoolCV">
//           <br />
//         </p>
//         <p className="schoolCV">
//           <br />
//         </p>
//         <p className="schoolCV colored">PT*</p>
//         <p className="schoolCV">
//           <br />
//         </p>
//         <p className="schoolCV">
//           <br />
//         </p>
//         <p className="schoolCV colored">PT</p>
//         <p className="schoolCV">
//           <br />
//         </p>
//         <p className="schoolCV">
//           <br />
//         </p>
//       </ParallaxLayer>

//       <ParallaxLayer offset={1.1}>
//         <p className="extraCV">
//           <br />
//         </p>
//         <p className="extraCV">
//           <br />
//         </p>
//         <p className="extraCV colored">Whales</p>
//         <p className="extraCV">
//           <br />
//         </p>
//         <p className="extraCV colored">InnoCup</p>
//         <p className="extraCV">
//           <br />
//         </p>
//         <p className="extraCV">
//           <br />
//         </p>
//         <p className="extraCV">
//           <br />
//         </p>
//         <p className="extraCV">
//           <br />
//         </p>
//         <p className="extraCV">
//           <br />
//         </p>
//         <p className="extraCV colored">Vulgarisation</p>
//         <p className="extraCV">
//           <br />
//         </p>
//         <p className="extraCV">
//           <br />
//         </p>
//         <p className="extraCV">
//           <br />
//         </p>
//         <p className="extraCV">
//           <br />
//         </p>
//         <p className="extraCV">
//           <br />
//         </p>
//         <p className="extraCV">
//           <br />
//         </p>
//         <p className="extraCV colored">Peyroux</p>
//         <p className="extraCV">
//           <br />
//         </p>
//         <p className="extraCV">
//           <br />
//         </p>
//         <p className="extraCV">
//           <br />
//         </p>
//         <p className="extraCV">
//           <br />
//         </p>
//         <p className="extraCV">
//           <br />
//         </p>
//         <p className="extraCV">
//           <br />
//         </p>
//         <p className="extraCV">
//           <br />
//         </p>
//         <p className="extraCV">
//           <br />
//         </p>
//         <p className="extraCV">
//           <br />
//         </p>
//         <p className="extraCV">
//           <br />
//         </p>
//       </ParallaxLayer>

//       <ParallaxLayer offset={1.1}>
//         <p className="hobbieCV colored">Chili</p>
//         <p className="hobbieCV colored">Bretagne</p>
//         <p className="hobbieCV colored">Piano</p>
//         <p className="hobbieCV">
//           <br />
//         </p>
//         <p className="hobbieCV">
//           <br />
//         </p>
//         <p className="hobbieCV colored">Vélo</p>
//         <p className="hobbieCV">
//           <br />
//         </p>
//         <p className="hobbieCV">
//           <br />
//         </p>
//         <p className="hobbieCV colored">Islande</p>
//         <p className="hobbieCV">
//           <br />
//         </p>
//         <p className="hobbieCV colored">Mexique</p>
//         <p className="hobbieCV">
//           <br />
//         </p>
//         <p className="hobbieCV colored">New York</p>
//         <p className="hobbieCV">
//           <br />
//         </p>
//         <p className="hobbieCV colored">Thaïlande</p>
//         <p className="hobbieCV"></p>
//         <p className="hobbieCV colored">Miami</p>
//         <p className="hobbieCV">
//           <br />
//         </p>
//         <p className="hobbieCV">
//           <br />
//         </p>
//         <p className="hobbieCV">
//           <br />
//         </p>
//         <p className="hobbieCV colored">South Africa</p>
//         <p className="hobbieCV">
//           <br />
//         </p>
//         <p className="hobbieCV colored">Cube</p>
//         <p className="hobbieCV">
//           <br />
//         </p>
//         <p className="hobbieCV">
//           <br />
//         </p>
//         <p className="hobbieCV">
//           <br />
//         </p>
//         <p className="hobbieCV colored">Vietnam</p>
//         <p className="hobbieCV">
//           <br />
//         </p>
//       </ParallaxLayer>

//       {/* </Fade> */}

//       {/* <ParallaxLayer offset={1.3}> 
//       <p className='yearCV' style={{ display: "block", width: "20%", marginLeft: "50%" }}>2020</p>
//       <p className='yearCV' style={{ display: "block", width: "20%", marginLeft: "50%" }}>2019</p>
//       <p className='yearCV' style={{ display: "block", width: "20%", marginLeft: "50%" }}>2018</p>
//       <p className='yearCV' style={{ display: "block", width: "20%", marginLeft: "50%" }}>2017</p>
//       <p className='yearCV' style={{ display: "block", width: "20%", marginLeft: "50%" }}>2016</p>
//       <p className='yearCV' style={{ display: "block", width: "20%", marginLeft: "50%" }}>2015</p>
//       <p className='yearCV' style={{ display: "block", width: "20%", marginLeft: "50%" }}>2014</p>
//       <p className='yearCV' style={{ display: "block", width: "20%", marginLeft: "50%" }}>2013</p>
//       <p className='yearCV' style={{ display: "block", width: "20%", marginLeft: "50%" }}>2012</p>
//     </ParallaxLayer> */}

//       {/* <ParallaxLayer
//       offset={1}
//       speed={1}
//       style={{ backgroundColor: "#805E73" }}
//     /> */}

//       {/* <ParallaxLayer offset={1} speed={0} style={{ opacity: 1, color: "red" }}>
//       <p style={{ display: "block", width: "20%", marginLeft: "70%" }}>Test</p>
//       <p style={{ display: "block", width: "20%", marginLeft: "30%" }}>Test</p>
//       <p style={{ display: "block", width: "20%", marginLeft: "40%" }}>Test</p>
//       <p style={{ display: "block", width: "20%", marginLeft: "50%" }}>Test</p>
//       <p style={{ display: "block", width: "20%", marginLeft: "12%" }}>Test</p>
//     </ParallaxLayer> */}
//       <ParallaxLayer offset={3.35}>
//         <Footer siteTitle="etomal" />
//       </ParallaxLayer>
//       {/* </Parallax>
//        */}
//     </div>
//   )
// }
// export default CVPage
